const META_PROPS = {
    pageTitle: 'Shopee Indonesia Payment Processing',
    pageDescription: 'Shopee Indonesia Payment Processing',
    og: {
        url: 'https://www.shopee.com',
        type: 'website',
        image: 'https://www.shopee.com/images/logo-large.png',
        title: 'Shopee Indonesia Payment Processing',
        description: 'Shopee Indonesia Payment Processing',
    },
};
export default META_PROPS;

interface IframeProps {
    src: string;
    classname?: string;
}

const Iframe = ({ src, classname }: IframeProps): JSX.Element => (
    <iframe
        src={src}
        className={classname}
        frameBorder="0"
        allow="camera; microphone"
    />
);

export default Iframe;

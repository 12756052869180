import { MouseEvent } from 'react';
import { Trans } from 'react-i18next';

interface PopupProps {
    show?: boolean;
    title?: string;
    message?: string;
    children?: JSX.Element;
    onClick?: (e: MouseEvent) => void;
    redirection?: boolean;
    link?: JSX.Element;
    refreshAction?: () => void;
}

const Popup = ({
    show,
    title = 'Enter Title Here',
    message = 'Enter Some Messages',
    onClick,
    children,
    redirection = false,
    link,
    refreshAction = null,
}: PopupProps): JSX.Element => (
    <div className={`popup ${show && 'active'} ${redirection && 'redirect'}`}>
        {title && <h4 className="title">{title}</h4>}
        {children}
        <h4>{message}</h4>
        {link}
        <div className="action">
            <div className="line" />
            {refreshAction && (
                <div className="button-wrapper">
                    <button onClick={refreshAction}>
                        <h4>
                            {' '}
                            <Trans i18nKey="bca.registration.toast.dialog.reload" />
                        </h4>
                    </button>
                    <div className="vertical-line" />
                    <button onClick={onClick}>
                        <h4>
                            {' '}
                            <Trans i18nKey="bca.registration.toast.dialog.cancel" />
                        </h4>
                    </button>
                </div>
            )}
            {!refreshAction && (
                <button onClick={onClick}>
                    <h4>OK</h4>
                </button>
            )}
        </div>
    </div>
);

export default Popup;

interface LoaderProps {
    show: boolean;
}

const Loader = ({ show }: LoaderProps): JSX.Element => (
    <div className={`loading ${show && 'active'}`}>
        <div className="dots" />
    </div>
);

export default Loader;

import { Symbol } from '#components/global';

const Header = (): JSX.Element => (
    <div className="header">
        <div className="container">
            <div className="icon">
                <Symbol
                    visual="shopee:text"
                    stroke="color"
                    width="200"
                    height="40"
                    viewBox="0 0 192 65"
                />
            </div>
        </div>
    </div>
);

export default Header;

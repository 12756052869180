import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import isTopLevelDomainID from './i18nextDetector';

import en from '../locale/en.json';
import id from '../locale/id.json';

const langDetectorOptions = {
    order: ['isTopLevelDomainID', 'navigator'],
    checkWhitelist: true,
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(isTopLevelDomainID);

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            id: {
                translation: id,
            },
        },
        fallbackLng: 'id',
        detection: langDetectorOptions,
        whitelist: ['en', 'id'],
        debug: false,
    });

export default i18n;

// @ts-nocheck

import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { getParameterByName } from '#libraries/url';
import { isMobileDevice } from '#libraries/userAgent';
import Header from '#components/common/Header';
import Card from '#components/common/Card';
import { Icon, Layer, Loader, Popup } from '#components/global';

const ErrorPage = ({ data, statusCode, errMessage, type = 'registration' }) => {
    const [isRenderMobile, setIsRenderMobile] = useState(false);
    const [isRenderDesktop, setIsRenderDesktop] = useState(false);
    const [isRenderToast, setIsRenderToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [notification, setNotification] = useState({
        layer: true,
        popup: false,
        title: '',
        message: '',
        loader: true,
        redirection: false,
    });
    const redirectForm = useRef(null);

    const determineError = (code) => {
        switch (code) {
            case 'card:registration:exceed-card-count':
                return (
                    <Trans i18nKey="bri.registration.toast.error.exceedCardCount" />
                );
            default:
                return <Trans i18nKey="bri.registration.toast.error.others" />;
        }
    };

    const redirect = () => {
        const locationHref = window.location.href;
        const redirect = getParameterByName('redirect', locationHref);
        redirectForm.current.setAttribute('action', redirect);
        redirectForm.current.submit();
    };

    const toggle = (element) => {
        switch (element) {
            case 'popup':
                if (notification.redirection) {
                    setTimeout(() => {
                        redirect();
                        setIsLoading(true);
                        setNotification({
                            layer: !notification.layer,
                            popup: !notification.popup,
                        });
                    }, 500);
                } else {
                    setNotification({
                        layer: !notification.layer,
                        popup: !notification.popup,
                    });
                }
                break;
        }
    };

    const locale = (element, status, page) => {
        switch (element) {
            case 'title':
                if (status === 'EXPIRED') {
                    switch (page) {
                        case 'checkout':
                            return (
                                <Trans i18nKey="error.views.expired.checkout.title" />
                            );
                        case 'limit':
                            return (
                                <Trans i18nKey="error.views.expired.limit.title" />
                            );
                        default:
                            return (
                                <Trans i18nKey="error.views.expired.registration.title" />
                            );
                    }
                } else {
                    return <Trans i18nKey="error.views.error.title" />;
                }
            case 'message':
                if (status === 'EXPIRED') {
                    switch (page) {
                        case 'checkout':
                            return (
                                <Trans i18nKey="error.views.expired.checkout.message" />
                            );
                        case 'limit':
                            return (
                                <Trans i18nKey="error.views.expired.limit.message" />
                            );
                        default:
                            return (
                                <Trans i18nKey="error.views.expired.registration.message" />
                            );
                    }
                } else {
                    switch (errMessage) {
                        case 'system:common:error':
                            return (
                                <Trans i18nKey="error.views.error.message" />
                            );
                        default:
                            return errMessage;
                    }
                }
        }
    };

    useEffect(() => {
        setIsRenderDesktop(!isMobileDevice());
        setIsRenderMobile(isMobileDevice());
        if (errMessage === 'card:registration:exceed-card-count') {
            setIsRenderToast(true);
            setNotification({
                layer: true,
                popup: true,
                title: '',
                message: determineError(errMessage),
                redirection: true,
                triggerSlider: true,
            });
        }
    }, []);

    const icon = (state) => {
        if (state === 'EXPIRED') {
            return (
                <Icon height="70" width="70" aria="clock" stroke="#EDA500" />
            );
        }
        return <Icon height="70" width="70" aria="warning:circle" />;
    };

    return (
        <div className="error">
            {isRenderDesktop && !isRenderToast && <Header />}
            <div className="container-body">
                <div className="card-wrapper">
                    {isRenderToast && (
                        <>
                            <div className="descriptive" data-group="modal">
                                <Layer show={isLoading} />
                                <Loader show={isLoading} />
                                <Popup
                                    show={notification.popup}
                                    title={notification.title}
                                    message={notification.message}
                                    onClick={() => toggle('popup')}
                                    redirection={notification.redirection}
                                />
                                <Layer show={notification.layer} classname="" />
                            </div>
                            <form ref={redirectForm} method="post">
                                <input
                                    type="hidden"
                                    value={data?.merchant_user_id}
                                    name="merchant_user_id"
                                    readOnly
                                />
                                <input
                                    type="hidden"
                                    value={
                                        data?.merchant_direct_debit_card_ref_id
                                    }
                                    name="merchant_direct_debit_card_ref_id"
                                    readOnly
                                />
                            </form>
                        </>
                    )}
                    {isRenderDesktop && !isRenderToast && (
                        <Card height="auto">
                            <div className="card-content">
                                <div className="content">
                                    {icon(statusCode)}
                                    <h3>
                                        <strong>
                                            {locale('title', statusCode, type)}
                                        </strong>
                                    </h3>
                                    <h5>
                                        {locale('message', statusCode, type)}
                                    </h5>
                                </div>
                            </div>
                        </Card>
                    )}
                    {isRenderMobile && !isRenderToast && (
                        <div className="content">
                            {icon(statusCode)}
                            <h3>
                                <strong>
                                    {locale('title', statusCode, type)}
                                </strong>
                            </h3>
                            <h3>{locale('message', statusCode, type)}</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ErrorPage.getInitialProps = ({ res, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    let errMessage = '';

    if (statusCode === 404) {
        errMessage = 'Page not found';
    } else {
        errMessage = 'Please try again later';
    }

    return { statusCode, errMessage };
};

export default ErrorPage;

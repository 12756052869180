const OTPInput = ({
    reference,
    onChange,
    length,
    className,
    handleError,
    handleSubmit,
}) => {
    const emptyCurrentActiveNode = () => {
        const OTPpins = document.getElementsByClassName(className);

        for (let i = 0; i < OTPpins.length; i++) {
            if (!OTPpins[i].getAttribute('readonly')) {
                OTPpins[i].value = '';
            }
        }
    };

    const pasteToOtpPins = (pastedData) => {
        const data = pastedData.split('').reverse();
        const OTPpins = document.getElementsByClassName(className);
        const OTPlast = document.getElementById('lastOTP');

        for (let i = 0; i < OTPpins.length; i++) {
            if (data[data.length - 1] >= '0' && data[data.length - 1] <= '9') {
                if (OTPpins[i].value === '') {
                    OTPpins[i].value = data.pop();
                    OTPpins[i].setAttribute('readonly', true);
                    if (i === OTPpins.length - 1) {
                        OTPlast.removeAttribute('readonly');
                        OTPlast.focus();
                    }
                    if (data.length === 0 && i < OTPpins.length - 1) {
                        OTPpins[i + 1].removeAttribute('readonly');
                        OTPpins[i + 1].focus();
                        break;
                    }
                }
            } else if (data.length > 0) {
                handleError();
                setTimeout(() => {
                    const pinToDelete = OTPpins;
                    for (let j = 0; j < pinToDelete.length; j++) {
                        const pinDeleted = pinToDelete[j];
                        if (j === 0) {
                            OTPpins[j].focus();
                            pinDeleted.removeAttribute('readonly');
                        } else {
                            pinDeleted.setAttribute('readonly', true);
                        }
                        pinDeleted.classList.remove('active');
                        pinDeleted.value = null;
                    }
                }, 300);
                break;
            }
        }

        let OTPstring = '';
        for (let i = 0; i < OTPpins.length; i++) {
            OTPstring += OTPpins[i].value;
        }
        onChange(OTPstring);
    };

    const handleKeyup = (e) => {
        if (!(e.target.value >= 0 && e.target.value <= 9)) {
            e.target.value = null;
            e.target.focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        if (e.target.type === 'tel') {
            const data = e.clipboardData.getData('Text');
            pasteToOtpPins(data);
        }
    };

    const getOTPString = (OTPpins) => {
        let OTPstring = '';
        for (let i = 0; i < OTPpins.length; i++) {
            OTPstring += OTPpins[i].value;
        }
        return OTPstring;
    };

    const handleKeyDown = (e) => {
        const OTPpins = document.getElementsByClassName(className);
        const OTPlast = document.getElementById('lastOTP');

        if (e.ctrlKey || e.metaKey || e.isComposing || e.keyCode === 229) {
            // ctrlKey, metaKey: stop reading keydown event if meta key is pressed
            // isComposing, keyCode === 229: stop reading keydown event that are part of composition (ex: cn/jp key event)
            return true;
        }

        e.preventDefault();

        if (e.key !== 'Unidentified') {
            if (
                parseInt(e.key) >= 0 &&
                parseInt(e.key) <= 9 &&
                e.key !== 'Unidentified' &&
                e.target.value === '' &&
                !e.target.readOnly
            ) {
                e.target.value = e.key;
                if (e.target === OTPlast) {
                    OTPlast.blur();
                    OTPlast.focus();
                } else if (
                    e.target.nextElementSibling.value === null ||
                    e.target.nextElementSibling.value === ''
                ) {
                    e.target.nextElementSibling.removeAttribute('readonly');
                    e.target.nextElementSibling.focus();
                    e.target.setAttribute('readonly', true);
                } else {
                    return false;
                }
            } else if (e.key === 'Backspace') {
                if (e.target.id === 'lastOTP' && e.target.value !== '') {
                    e.target.value = null;
                    e.target.focus();
                } else if (
                    e.target.value === '' &&
                    e.target.previousElementSibling &&
                    e.target.previousElementSibling.value !== ''
                ) {
                    e.target.previousElementSibling.value = null;
                    e.target.previousElementSibling.removeAttribute('readonly');
                    e.target.previousElementSibling.focus();
                    e.target.setAttribute('readonly', true);
                }
            } else if (
                e.key === 'Enter' &&
                getOTPString(OTPpins).length === OTPpins.length
            ) {
                handleSubmit();
            } else if (!(parseInt(e.key) >= 0 && parseInt(e.key) <= 9)) {
                handleError();
                e.target.focus();
            }
        }
        onChange(getOTPString(OTPpins));
    };

    const handleClick = () => {
        const OTPpins = document.getElementsByClassName(className);
        for (let i = 0; i < OTPpins.length; i++) {
            if (!OTPpins[i].getAttribute('readonly')) {
                OTPpins[i].focus();
                break;
            }
        }
    };

    const handleInput = (e) => {
        e.preventDefault();
        if (e.key === undefined) {
            const pastedData = e.target.value;
            emptyCurrentActiveNode(); // used for overriding default paste from mobile keyboard
            pasteToOtpPins(pastedData);
        }
    };

    const renderOTPPins = () => {
        const OTPpins = [];

        for (let i = 0; i < length; i++) {
            if (i === length - 1) {
                OTPpins.push(
                    <input
                        key={i}
                        type="tel"
                        className={className}
                        autoComplete="one-time-code"
                        pattern="[0-9]"
                        onClick={() => handleClick()}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => handlePaste(e)}
                        onKeyUp={(e) => handleKeyup(e)}
                        onInput={(e) => handleInput(e)}
                        id="lastOTP"
                    />
                );
            } else {
                OTPpins.push(
                    <input
                        key={i}
                        type="tel"
                        className={className}
                        autoComplete="one-time-code"
                        pattern="[0-9]"
                        onClick={() => handleClick()}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => handlePaste(e)}
                        onKeyUp={(e) => handleKeyup(e)}
                        onInput={(e) => handleInput(e)}
                    />
                );
            }
        }

        return OTPpins;
    };

    return (
        <div ref={reference} className="pinsOTP">
            {renderOTPPins()}
        </div>
    );
};

export default OTPInput;

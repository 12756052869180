export const { NODE_ENV } = process.env;
export const { PORT } = process.env;
export const CORE_API = process.env.NEXT_PUBLIC_URL;
export const CORE_KEY = process.env.NEXT_PUBLIC_KEY;
export const BCA_REGISTRATION_MOCK_URL =
    process.env.NEXT_PUBLIC_BCA_REGISTRATION_MOCK_URL;
export const BASEPATH = process.env.NEXT_PUBLIC_BASEPATH;
export const ENV = process.env.NEXT_PUBLIC_ENV;
export const TRACK_ID = process.env.NEXT_PUBLIC_TRACK_ID;
export const MDAP_APP_NAME = process.env.NEXT_PUBLIC_MDAP_APP_NAME;
export const MDAP_SECRET = process.env.NEXT_PUBLIC_MDAP_SECRET;
export const AUTH_OPEN_API_URL = process.env.NEXT_PUBLIC_AUTH_OPEN_API_URL;
export const CHANNEL_API_URL = process.env.NEXT_PUBLIC_CHANNEL_API_URL;
export const BCA_REGISTRATION_MOCK_CLIENT_SECRET =
    process.env.NEXT_PUBLIC_BCA_REGISTRATION_MOCK_CLIENT_SECRET;
export const IS_BCA_USE_MOCK = Boolean(process.env.NEXT_PUBLIC_IS_BCA_USE_MOCK);

export const BCA_TEST_IFRAME = '/dd/client/bca/iframe';

const BRI_REGISTRATION_TOAST_ERROR = 'bri.registration.toast.error';

export const BRI_REGISTRATION_ERROR_MAP = (code: string): string => {
    switch (code) {
        case 'card:registration:invalid-card-number':
            return `${BRI_REGISTRATION_TOAST_ERROR}.invalidCard`;
        case 'card:registration:card-expired':
            return `${BRI_REGISTRATION_TOAST_ERROR}.expiredCard`;
        case 'card:registration:expired':
            return `${BRI_REGISTRATION_TOAST_ERROR}.registrationExpired`;
        case 'card:registration:invalid-phone-number':
            return `${BRI_REGISTRATION_TOAST_ERROR}.invalidPhone`;
        case 'card:registration:invalid-expiry-date':
            return `${BRI_REGISTRATION_TOAST_ERROR}.invalidExpiryDate`;
        case 'card:registration:bank-blocked':
            return `${BRI_REGISTRATION_TOAST_ERROR}.bankBlocked`;
        case 'card:registration:invalid-otp':
            return `${BRI_REGISTRATION_TOAST_ERROR}.invalidOTP`;
        case 'card:registration:bank-error':
            return `${BRI_REGISTRATION_TOAST_ERROR}.bankError`;
        case 'card:registration:hit-max-otp':
            return `${BRI_REGISTRATION_TOAST_ERROR}.maxOTPHit`;
        case 'card:registration:request-max-otp':
            return `${BRI_REGISTRATION_TOAST_ERROR}.maxOTP`;
        case 'card:registration:already-registered':
            return `${BRI_REGISTRATION_TOAST_ERROR}.alreadyRegistered`;
        case 'card:registration:exceed-card-count':
            return `${BRI_REGISTRATION_TOAST_ERROR}.exceedCardCount`;
        case 'card:registration:failed-send-otp':
            return `${BRI_REGISTRATION_TOAST_ERROR}.failedOTP`;
        case 'card:registration:attempt-exceed-limit':
            return `${BRI_REGISTRATION_TOAST_ERROR}.exceedLimit`;
        case 'card:registration:expired-otp':
            return `${BRI_REGISTRATION_TOAST_ERROR}.expiredOTP`;
        case 'system:common:error':
            return `${BRI_REGISTRATION_TOAST_ERROR}.common`;
        case 'system:common:invalid-request':
            return `${BRI_REGISTRATION_TOAST_ERROR}.invalidRequest`;
        case 'system:common:timeout':
            return `${BRI_REGISTRATION_TOAST_ERROR}.commonTimeout`;
        default:
            return `${BRI_REGISTRATION_TOAST_ERROR}.others`;
    }
};

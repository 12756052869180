import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '#libraries/i18nextInit';
import Meta from '../Meta';

const Layout = (props) => {
    useEffect(() => {}, []);

    return (
        <I18nextProvider i18n={i18n}>
            <Meta {...props} />
            {props.children}
        </I18nextProvider>
    );
};

export default Layout;

export const addLeadingZero = (num) => {
    if (num.length === 1) {
        return `0${num}`;
    }

    return num;
};

export const formatDate = (date, type) => {
    const year = date.getFullYear().toString();
    const month = addLeadingZero((date.getMonth() + 1).toString());
    const day = addLeadingZero(date.getDate().toString());
    const hours = addLeadingZero(date.getHours().toString());
    const minutes = addLeadingZero(date.getMinutes().toString());
    const seconds = addLeadingZero(date.getMonth().toString());

    switch (type) {
        case 'recon':
            return `${day}${month}${year}`;
        default:
            return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }
};

const setMonth = (month) => {
    switch (month) {
        case 1:
            return 'Jan';
        case 2:
            return 'Feb';
        case 3:
            return 'Mar';
        case 4:
            return 'Apr';
        case 5:
            return 'May';
        case 6:
            return 'Jun';
        case 7:
            return 'Jul';
        case 8:
            return 'Aug';
        case 9:
            return 'Sep';
        case 10:
            return 'Oct';
        case 11:
            return 'Nov';
        default:
            return 'Dec';
    }
};

export const reviseDate = (date) => {
    const dateRender = new Date(date);
    const day = dateRender.getDate();
    const month = dateRender.getMonth() + 1;
    const year = dateRender.getFullYear();
    const hour = addLeadingZero(dateRender.getHours().toString());
    const minute = addLeadingZero(dateRender.getMinutes().toString());
    return `${day} ${setMonth(month)} ${year} at ${hour}:${minute}`;
};

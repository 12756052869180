import { AppProps } from 'next/app';
import { useEffect } from 'react';

import Layout from '#components/common/Layout';
import ErrorPage from '#pages/_error';

import '../public/styles/application/main.scss';
import { MDAP_APP_NAME, MDAP_SECRET } from '#constants/index';

const Monitoring = async () => {
    const { MdapSdk } = await import('@mdap/javascript-sdk');
    const ResourcePlugin = (await import('@mdap/sdk-plugin-resource')).default;
    const PagePerfPlugin = (await import('@mdap/sdk-plugin-performance'))
        .default;
    const JSExceptPlugin = (await import('@mdap/sdk-plugin-exception')).default;
    const ApiPlugin = (await import('@mdap/sdk-plugin-api')).default;

    const sdk = new MdapSdk({
        app_name: MDAP_APP_NAME,
        secret_key: MDAP_SECRET,
        environment: 'live',
        region: 'id',
        logger: true,
        app_version: 'v1.0.0',
        sample: 1,
    });

    const resourcePlugin = new ResourcePlugin({});
    const pagePerfPlugin = new PagePerfPlugin({});
    const jsExceptPlugin = new JSExceptPlugin({});
    const apiPlugin = new ApiPlugin({});

    sdk.use(resourcePlugin);
    sdk.use(pagePerfPlugin);
    sdk.use(jsExceptPlugin);
    sdk.use(apiPlugin);
};

const CoreDirectDebit = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        Monitoring();
    });
    return (
        <Layout>
            {pageProps.error ? (
                <ErrorPage
                    data={{}}
                    statusCode={pageProps.error.statusCode}
                    errMessage={pageProps.error.errMessage}
                    type={pageProps.error.type}
                />
            ) : (
                <Component {...pageProps} />
            )}
        </Layout>
    );
};

export default CoreDirectDebit;

export default {
    name: 'isTopLevelDomainID',

    lookup() {
        if (typeof window !== 'undefined') {
            const userAgentInfo = window.navigator.userAgent.split(' ');
            for (let i = 0; i < userAgentInfo.length; i++) {
                if (userAgentInfo[i].includes('locale')) {
                    const language = userAgentInfo[i].includes('=')
                        ? userAgentInfo[i].split('=')[1]
                        : userAgentInfo[i].split('/')[1];
                    if (language === 'en' || language === 'id') {
                        return language;
                    }
                    break;
                }
            }
        }
        return 'id';
    },
};

import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { addLeadingZero } from 'src/libraries/formatDate';

interface TimerProps {
    timeTillDate?: string;
    check?: () => void;
    wording?: any;
    timerLocalStorageKey: string;
}

const Timer = ({
    timeTillDate,
    check,
    wording,
    timerLocalStorageKey,
}: TimerProps): JSX.Element => {
    const [timeTo, setTimeTo] = useState({
        minutes: undefined,
        seconds: undefined,
    });

    dayjs.extend(duration);

    useEffect(() => {
        const expiryTime: dayjs.Dayjs = dayjs(timeTillDate);
        const interval = setInterval(() => {
            const currentTime: dayjs.Dayjs = dayjs();
            const timeDiff: duration.Duration = dayjs.duration(
                expiryTime.diff(currentTime)
            );
            const minutes = addLeadingZero(timeDiff.minutes().toString());
            const seconds = addLeadingZero(timeDiff.seconds().toString());
            setTimeTo({ minutes, seconds });

            if (!currentTime.isBefore(expiryTime)) {
                clearInterval(interval);
                if (document.getElementById('timer')) {
                    document.getElementById('timer').style.display = 'none';
                }
                localStorage.removeItem(
                    `OTPRequestNext-${timerLocalStorageKey}`
                );
                localStorage.removeItem(`OTPRetryNext-${timerLocalStorageKey}`);
                check();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div id="timer">
            <p>
                {wording}
                {!timeTo.minutes && !timeTo.seconds
                    ? null
                    : ` : (${timeTo.minutes}:${timeTo.seconds})`}
            </p>
        </div>
    );
};

export default Timer;

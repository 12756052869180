import { Fragment, MouseEventHandler } from 'react';

interface IconProps {
    children?: JSX.Element;
    aria?: string;
    classname?: string;
    width?: string;
    height?: string;
    viewBox?: string;
    stroke?: string;
    click?: MouseEventHandler;
}

const Icon = ({
    children,
    aria,
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    stroke = 'currentColor',
    classname,
    click,
}: IconProps): JSX.Element => {
    const determineIcon = (icon) => {
        switch (icon) {
            case 'clock': {
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <polyline points="12 6 12 12 16 14" />
                    </>
                );
            }
            case 'warning:circle': {
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="12" />
                        <line x1="12" y1="16" x2="12.01" y2="16" />
                    </>
                );
            }
            case 'close': {
                return (
                    <>
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </>
                );
            }
            case 'help': {
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                        <line x1="12" y1="17" x2="12.01" y2="17" />
                    </>
                );
            }
            case 'chevron:up': {
                return <polyline points="18 15 12 9 6 15" />;
            }
            case 'chevron:down': {
                return <polyline points="6 9 12 15 18 9" />;
            }
            case 'arrow:left': {
                return (
                    <>
                        <line x1="19" y1="12" x2="5" y2="12" />
                        <polyline points="12 19 5 12 12 5" />
                    </>
                );
            }
            case 'check': {
                return (
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                    />
                );
            }
            default: {
                return children;
            }
        }
    };

    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={classname}
            onClick={click}
        >
            {determineIcon(aria)}
        </svg>
    );
};

export default Icon;

import Head from 'next/head';
import PropTypes from 'prop-types';

import META_PROPS from './contants';
import { BASEPATH } from '#constants/index';

const Meta = ({ pageTitle, pageDescription, og }) => (
    <Head>
        <meta content="#F7482F" name="theme-color" />
        <meta content="#F7482F" name="msapplication-navbutton-color" />
        <meta content="#F7482F" name="apple-mobile-web-app-status-bar-style" />

        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />

        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=0"
        />
        <meta name="format-detection" content="telephone=no" />

        <link
            key="favIcon"
            rel="shortcut icon"
            type="image/png"
            href={`${
                BASEPATH === '/' ? '' : BASEPATH
            }/static/images/brand/shopee-orange.ico`}
        />
        <link
            rel="manifest"
            href={`${BASEPATH === '/' ? '' : BASEPATH}/manifest.json`}
        />
        <meta key="ogUrl" property="og:url" content={og.url} />
        <meta key="ogType" property="og:type" content={og.type} />
        <meta key="ogImage" property="og:image" content={og.image} />
        <meta key="ogTitle" property="og:title" content={og.title} />
        <meta key="ogDesc" property="og:description" content={og.description} />
        <link
            rel="icon"
            sizes="192x192"
            href={`${
                BASEPATH === '/' ? '' : BASEPATH
            }/static/images/brand/shopee-orange.ico`}
        />
        <link
            rel="apple-touch-icon"
            href={`${
                BASEPATH === '/' ? '' : BASEPATH
            }/static/images/brand/shopee-orange.ico`}
        />
        <meta name="msapplication-square310x310logo" content="/icons-512.png" />

        {og.updatedTime && (
            <meta
                key="ogUpdatedTime"
                property="og:updated_time"
                content={og.updatedTime}
            />
        )}
        <meta
            key="pageDescription"
            name="description"
            content={pageDescription}
        />
        <title key="pageTitle">{pageTitle}</title>
    </Head>
);

Meta.propTypes = {
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
    og: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        type: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        updatedTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
};

Meta.defaultProps = META_PROPS;

export default Meta;

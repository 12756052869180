import { MouseEvent } from 'react';

interface LayerProps {
    show?: boolean;
    onClick?: (e: MouseEvent) => void;
    classname?: string;
}

const Layer = ({ show, onClick, classname }: LayerProps): JSX.Element => (
    <div
        className={`layer ${show && 'active'} ${classname}`}
        onClick={onClick}
    />
);

export default Layer;

interface CardProps {
    children: JSX.Element[];
    height?: string;
}

const Card = ({ children, height = 'fixed' }: CardProps): JSX.Element => (
    <div className={`card height:${height}`}>{children}</div>
);

export default Card;
